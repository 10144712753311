<template>
  <div>
    <el-form ref="form" label-width="300px">
      <MyFormItem label="Current Table Index" tooltip="Table Index đang active">
        <el-input class="form-input" v-model="data.TableIndex" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="Table Index" tooltip="Chọn table index cần show leaderboard">
        <el-input-number v-model="showLeaderBoard.tableIndex" :step="1" size="small" :min="0" :max="6"/>
      </MyFormItem>

      <MyFormItem label="Group name" tooltip="Chọn group name cần show leaderboard">
        <el-select v-model="showLeaderBoard.groupName">
          <el-option v-for="data in this.groupTypeSelect" :key="data.value" :label="data.value" :value="data.value"/>
        </el-select>
      </MyFormItem>

      <MyFormItem label="Group name custom" tooltip="">
        <el-input class="form-input" v-model="showLeaderBoard.groupNameCustom" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Leaderboard Index" tooltip="Chọn leaderboard index cần show leaderboard">
        <el-input-number v-model="showLeaderBoard.lbIndex" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-button type="primary" @click="getLeaderBoard()">
        Get Leaderboard
      </el-button>

      <br/>
      <br/>

      <el-table :data="showLeaderBoard.result" border v-if="showLeaderBoard.result.length > 0">
        <el-table-column
            prop="Rank"
            label="Rank"/>
        <el-table-column
            prop="UserId"
            label="UserId"/>
        <el-table-column
            prop="Score"
            label="Score"/>
      </el-table>

      <MyFormItem label="Table Index" tooltip="Chọn table index cần show leaderboard">
        <el-input-number v-model="showUserInfo.tableIndex" :step="1" size="small" :min="0" :max="6"/>
      </MyFormItem>

      <MyFormItem label="UserId" tooltip="Nhập userId để search thông tin">
        <el-input class="form-input" v-model="showUserInfo.userId" size="normal" clearable/>
      </MyFormItem>

      <el-button type="primary" @click="getUserInfo()">
        Get User Info
      </el-button>

      <br/>
      <br/>

      <el-table :data="showUserInfo.result" border v-if="showUserInfo.result.length > 0">
        <el-table-column
            prop="UserId"
            label="UserId"/>
        <el-table-column
            prop="Score"
            label="Score"/>
        <el-table-column
            prop="Rank"
            label="Rank"/>
        <el-table-column
            prop="GroupName"
            label="GroupName"/>
      </el-table>

      <br/>
      <br/>

    </el-form>
  </div>
</template>

<script>

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import moment from 'moment'

export default {
  components:{
    MyFormItem
  },
  props: {
    data: {},
  },
  data: () => {
    return {
      showLeaderBoard: {
        tableIndex: 0,
        groupName: "",
        groupNameCustom: "",
        lbIndex: 0,
        result: [],
      },
      showUserInfo: {
        tableIndex: 0,
        userId: "",
        result: []
      },
    }
  },
  computed: {
    groupTypeSelect: {
      get: function() {
        let options = [];
        for (let i = 0; i < this.data.GroupConfig.length; i++) {
          options.push({
            value: this.data.GroupConfig[i].GroupName,
          });
        }

        return options;
      },
    },
  },
  methods: {
    async getLeaderBoard() {
      if (this.showLeaderBoard.groupName === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showLeaderBoard.result = [];
        let groupName = this.showLeaderBoard.groupName;
        if (this.showLeaderBoard.groupNameCustom !== "") {
          groupName = this.showLeaderBoard.groupNameCustom;
        }
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-leaderboard-detail/${this.data.TypeLb}_${this.showLeaderBoard.tableIndex}_${groupName}_${this.showLeaderBoard.lbIndex}`,
          method: `get`,
        });

        let response = responseFull.data;

        this.$root.$emit("event-change-loading", false);

        for (let i = 0; i < response.Lb.length; i++) {
          this.showLeaderBoard.result.push({
            Rank: i + 1,
            UserId: response.Lb[i].UserId,
            Score: response.Lb[i].Score,
          });
        }
      }
      catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async getUserInfo() {
      if (this.showUserInfo.userId === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showUserInfo.result = [];
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-user-score-in-table/${this.data.TypeLb}/${this.showUserInfo.tableIndex}/${this.showUserInfo.userId}`,
          method: `get`,
        });

        let response = responseFull.data.Data;

        this.$root.$emit("event-change-loading", false);

        for (let i = 0; i < response.length; i++) {
          let showData = {
            UserId: response[i].UserId,
            Score: response[i].Score,
            Rank: response[i].Rank,
            GroupName: response[i].GroupName,
          };

          this.showUserInfo.result.push(showData);
        }
      }
      catch (ex) {
        Message({
          message: `Get user info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>